<template lang="pug">
.Help
  .banderaSvgMobile
  Modal(:header="true" :goBack="onGoBack")
    template(#body)
      .d-flex.justify-content-center.align-items-center
        img(
          src="@/assets/mobileFSFB/error.png"
          alt="confirm_data"
        ).image-confirm_data.pl-3
        h2.text-confirm_data.ml-3: strong Error
      article.pl-2.pt-3.containerData.mb-4.pb-1
        p.mb-1.containerFontModal
          span.ml-1 Error de servicio
      .d-flex.justify-content-between.pt-3.pb-4
        button(
          @click="onAgain"
        ).buttonConfirm.px-4.py-2.w-100.btnConfirmData
          span(v-if="isLoadingAgain && isLoadingAgain")
            MiniSpinner
          span(v-else) Reintentar
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Help",

  data() {
    return {
      isLoadingAgain: false
    };
  },

  components: {
    Modal: () => import("./components/Modal.vue"),
    MiniSpinner: () => import("./components/MiniSpinner.vue")
  },

  computed: {
    ...mapState({
      actionError: state => state.mobileFSFB.actionError,
      fromError: state => state.mobileFSFB.fromError
    })
  },

  methods: {
    ...mapActions({
      goBack: "mobileFSFB/goBack"
    }),

    ...mapMutations({
      setState: "mobileFSFB/setState"
    }),

    onGoBack() {
      this.goBack();
      this.setState({ key: "loadHome", value: true });
    },

    async onAgain() {
      this.isLoadingAgain = true;
      try {
        await this.actionError();

        if (this.fromError === "Home") {
          this.goBack();
          this.setState({ key: "loadApi", value: false });
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingAgain = false;
    }
  }
};
</script>

<style lang="scss">
.Help {
  height: 100%;
}

.blueText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #69cdfe;
}

.logoHelp {
  top: 0;
  left: auto;
  transform: none;
  margin-top: 56px;
  margin-bottom: 20%;
  position: relative;
}

.text-confirm_data {
  width: 177px;
}

.boxDarker {
  background: #202d78;
  border-radius: 20px;
}

.btnConfirmData {
  max-width: 200px;
  margin: auto;
}

@media screen and (max-width: 330px) {
  .buttonConfirm {
    padding: 0.5rem 1rem !important;
  }
}
</style>
